.spinner-container {
    position: fixed; /* Fixed positioning to overlay content */
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh; /* Full viewport height */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
    z-index: 999; /* Ensure it's above other content */
  }
  
  .spinner-container p {
    margin-top: 20px; /* Space between spinner and text */
    font-size: 18px;
    color: #333;
  }
  